import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TENANT_KEY } from '@app/app.component';
import { BaseService } from '@services/base.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class TenantService extends BaseService {
  private readonly tenantKey = TENANT_KEY;

  constructor(
    protected override http: HttpClient,
    protected override messageService: MessageService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    super(http, messageService);
  }

  initialize(): void {
    const domainSplit = this.document.location.hostname.split('--');
    if (domainSplit.length <= 1) return; // TODO: redirect to a 404 page
    const tenant = domainSplit[0];
    if (tenant == null) return; // TODO: redirect to a 404 page
    this.setActiveTenant(tenant.toUpperCase());
  }

  getActiveTenant(): string | null {
    return localStorage.getItem(this.tenantKey);
  }

  setActiveTenant(tenant: string): void {
    localStorage.setItem(this.tenantKey, tenant);
  }
}
