import { KeycloakProfile } from 'keycloak-js';

export const getUserFullName = (profile: KeycloakProfile): string => {
  const { firstName, lastName } = profile;
  const formattedFirstName =
    firstName === undefined
      ? undefined
      : `${firstName.charAt(0).toUpperCase()}${firstName.slice(1)}`;
  const formattedLastName = lastName?.toUpperCase();

  if (formattedFirstName === undefined && formattedLastName === undefined) {
    return '';
  }

  if (formattedFirstName === undefined && formattedLastName !== undefined) {
    return formattedLastName;
  }

  if (formattedFirstName !== undefined && formattedLastName === undefined) {
    return formattedFirstName;
  }

  return `${formattedLastName}${' '}${formattedFirstName}`;
};
